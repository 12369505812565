<template lang="pug">
    #socials(:style="`background-image: url('${image}')`")
        .container
            .title
                Editor(:contents="editor")
            ul
                li(v-for="rede in filteredSettings")
                   a(:href="rede.value")
                        .icon
                            FontAwesomeIcon(:icon="rede.icon")
                        .text
                            span.access Acessar
                            span.call {{rede.attribute}}
</template>

<script>
import { props } from '@/mixins/component'
import Editor from '@sections/Pagina/Editor/Editor'
import Link from '@components/Link/Link'
import { faInstagram, faFacebookSquare, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

export default {
    name: "section-alimentos-coamo",
    props,
    components: {
        Editor,
        Link,
    },
    data() {
        return {
            redes: [
                {
                    icon: faInstagram,
                    name: 'instagram',
                },
                {
                    icon: faFacebookSquare,
                    name: 'facebook',
                },
                {
                    icon: faLinkedin,
                    name: 'linkedin',
                },
                {
                    icon: faYoutube,
                    name: 'youtube',
                },
            ]
        }
    },
    created() {
        this.$store.dispatch('fetchSettings')
    },
    computed: {
        settings() {
            return this.$store.state.settings
        },
        editor() {
            return {content: this.contents.content.editor}
        },
        image() {
            return `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${this.contents.content.image}`
        },
        filteredSettings() {
            const newLista = this.settings
                .filter(rede => this.redes
                .map(rede => rede.name)
                .includes(rede.attribute))
            return newLista.map(rede => ({
                ...rede,
                icon: this.redes.find(redeIcon => redeIcon.name === rede.attribute).icon
            }))
        }
    }
}
</script>

<style lang="stylus" scoped src="./SocialsRibbonSection.styl"></style>
